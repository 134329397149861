const initialState = {
  create_response: null,
  loading: false,
  error_response: null,
};
const newLeadReducers = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_RESPONSE":
      return {
        ...state,
        create_response: action.payload,
      };
    case "ERROR_RESPONSE":
      return {
        ...state,
        error_response: action.payload,
      };
    case "CREATE_LOADER":
      return {
        ...state,
        loading: action.payload,
      };
    case "CREATE_RESPONSE_CLEAR":
      return {
        ...state,
        create_response: null,
      };
    default:
      return { ...state };
  }
};
export default newLeadReducers;
